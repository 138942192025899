// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav {
    position: fixed;
    bottom: 1.5rem;
    left: 0;
    right: 0;
    background-color: var(--black-color);
    width: 88%;
    margin-inline: auto;
    box-shadow: 0 2px 16px hsla(228, 95%, 4%, 0.1);
    padding: 1rem 1.75rem;
    border-radius: 4rem;
    margin-right: auto;
}

.nav_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav_link {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.5rem;
    transition: color 0.4s;

    background-color: var(--black-color);
    border: none;
}

.nav_link:hover {
    color: var(--white-color);
}

ul {
    list-style: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/bottomBar/bottomBar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,OAAO;IACP,QAAQ;IACR,oCAAoC;IACpC,UAAU;IACV,mBAAmB;IACnB,8CAA8C;IAC9C,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,qBAAqB;IACrB,iBAAiB;IACjB,sBAAsB;;IAEtB,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".nav {\n    position: fixed;\n    bottom: 1.5rem;\n    left: 0;\n    right: 0;\n    background-color: var(--black-color);\n    width: 88%;\n    margin-inline: auto;\n    box-shadow: 0 2px 16px hsla(228, 95%, 4%, 0.1);\n    padding: 1rem 1.75rem;\n    border-radius: 4rem;\n    margin-right: auto;\n}\n\n.nav_list {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.nav_link {\n    color: var(--text-color);\n    text-decoration: none;\n    font-size: 1.5rem;\n    transition: color 0.4s;\n\n    background-color: var(--black-color);\n    border: none;\n}\n\n.nav_link:hover {\n    color: var(--white-color);\n}\n\nul {\n    list-style: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
