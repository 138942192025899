// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    position: absolute;
    top: 20px;
    left: 20px;
}

.brand-name {
    font-size: 24px;
    font-weight: bold;
    color: #ffb300;
}

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
    text-align: center;
}

.title {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 30px;
}

.button-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
}

.welcome-btn-primary,
.welcome-btn-secondary {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
    text-align: center;
}

.welcome-btn-primary {
    background: #ffb300;
    color: white;
}

.welcome-btn-secondary {
    background-color: #ffffff;
    color: #ffb300;
    border: 2px solid #ffb300;
}

.welcome-btn:hover {
    transform: scale(1.05);
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/welcome/welcome.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,gBAAgB;AACpB;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,wCAAwC;IACxC,qDAAqD;IACrD,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".header {\n    position: absolute;\n    top: 20px;\n    left: 20px;\n}\n\n.brand-name {\n    font-size: 24px;\n    font-weight: bold;\n    color: #ffb300;\n}\n\n.main-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 10vh;\n    text-align: center;\n}\n\n.title {\n    font-size: 28px;\n    font-weight: bold;\n    color: #333;\n    margin-bottom: 30px;\n}\n\n.button-group {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    max-width: 300px;\n}\n\n.welcome-btn-primary,\n.welcome-btn-secondary {\n    width: 100%;\n    height: 45px;\n    border: none;\n    border-radius: 6px;\n    cursor: pointer;\n    font-size: 1em;\n    font-weight: bold;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    transition: box-shadow 0.3s ease, transform 0.3s ease;\n    margin-top: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 15px 20px;\n    text-align: center;\n}\n\n.welcome-btn-primary {\n    background: #ffb300;\n    color: white;\n}\n\n.welcome-btn-secondary {\n    background-color: #ffffff;\n    color: #ffb300;\n    border: 2px solid #ffb300;\n}\n\n.welcome-btn:hover {\n    transform: scale(1.05);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
