// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-container {
    bottom: 100px;
    width: 120%;
    height: 20px;
    background-color: #e9e9e9;
    border-radius: 20px;
    overflow: hidden;
    margin: 20px 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar-fill {
    height: 100%;
    background-color: #ffb300;
    transition: width 0.3s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/questionnaire/progressBar/progressBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,8CAA8C;AAClD;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,kCAAkC;AACtC","sourcesContent":[".progress-bar-container {\n    bottom: 100px;\n    width: 120%;\n    height: 20px;\n    background-color: #e9e9e9;\n    border-radius: 20px;\n    overflow: hidden;\n    margin: 20px 0;\n    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);\n}\n\n.progress-bar-fill {\n    height: 100%;\n    background-color: #ffb300;\n    transition: width 0.3s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
