import React from "react";
import BottomBar from "../../bottomBar/BottomBar";
import "./store.css";

const Store = () => {
    return (
        <div>
            <h1>Shop</h1>
            <BottomBar />
        </div>
    );
};

export default Store;
