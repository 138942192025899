import React from "react";
import BottomBar from "../../bottomBar/BottomBar";

const Profile = () => {
    return (
        <div>
            <h1>Profile</h1>
            <BottomBar />
        </div>
    );
};

export default Profile;
